import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import Toast from "helpers/Toast";
import axios from "axios";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
// const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Input = styled.input((props) => [
  tw`mt-6 first:mt-0 
      border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`,
  props.required && props.wasSubmitted && props.value === '' && tw`text-red-500 border-red-500`,
]);
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const alertContent = () => {
  Toast.fire({
      icon: 'success',
      title: 'Mensaje enviado',
      position: 'top'
  });
}
const alertError = ({message = 'Algo salió mal'}) => {
  Toast.fire({
      icon: 'error',
      title: message,
      position: 'top'
  });
}

// Form initial state
const INITIAL_STATE = {
  name: '',
  lastname: '',
  email: '',
  company: '',
  state: '',
  text: ''
};

export default ({
  subheading = "Contáctanos",
  heading = <>Ánimate a ponerte <span tw="text-primary-500">en contacto</span><wbr/> con nosotros.</>,
  description = "¡Comparte tus ideas con nosotros y trabajemos juntos para crear un producto éxitoso!.",
  submitButtonText = "Enviar",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  const [contact, setContact] = useState(INITIAL_STATE);
  const [isLoading, setIsLoading] = useState(false);
  const [wasSubmitted, setWasSubmited] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setContact(prevState => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setWasSubmited(true);
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/22706172/8c2a986b-8208-4665-b120-62ae3fd8dd1a`;
      const { name, lastname, email, state, company, text } = contact;
      let emptyFields = Object.keys(contact).filter(key => contact[key] === '' && key !== 'state');
      if(emptyFields.length > 0) {
        alertError({message:'Faltan campos obligatorios por llenar'});
      } else {
        const payload = { 
          fields: [
            {
              type: "string",
              name: "firstname", 
              value: name
            },
            {
              type: "string",
              name: "lastname", 
              value: lastname
            },
            {
              type: "string",
              name: "email", 
              value: email
            },
            {
              type: "string",
              name: "company", 
              value: company
            },
            {
              type: "string",
              name: "state", 
              value: state
            },
            {
              type: "text",
              name: "message", 
              value: text
            }
        ] };
        const response = await axios.post(url, payload);
        if(response.status === 200){
          setContact(INITIAL_STATE);
          setWasSubmited(false);
          alertContent();
        } else {
          alertError("Algo salió mal...");
        }
      }
    } catch (error) {
      console.log(error);
      alertError("Favor de verificar los campos");
    }
    setIsLoading(false);
  }
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit} noValidate>
              <Input wasSubmitted={wasSubmitted} autoComplete="off" required type="text" name="name" placeholder="Nombre*" onChange={handleChange} value={contact.name} />
              <Input wasSubmitted={wasSubmitted} autoComplete="off" required type="text" name="lastname" placeholder="Apellidos*" onChange={handleChange} value={contact.lastname} />
              <Input wasSubmitted={wasSubmitted} autoComplete="off" required type="email" name="email" placeholder="Tu correo electrónico *" onChange={handleChange} value={contact.email} />
              <Input wasSubmitted={wasSubmitted} autoComplete="off" required type="text" name="company" placeholder="Empresa *" onChange={handleChange} value={contact.company} />
              <Input wasSubmitted={wasSubmitted} autoComplete="off" type="text" name="state" placeholder="Estado/Municipio" onChange={handleChange} value={contact.state} />
              <Textarea wasSubmitted={wasSubmitted} required name="text" placeholder="Tu mensaje *" onChange={handleChange} value={contact.text} />
              <SubmitButton type="submit" disabled={isLoading}>{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
