import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import AgencyTeamRight from '../images/photo-1519389950473-47ba0277781c.jpeg';
import AgencyTeamLeft from '../images/doctype-hi-res.jpeg';
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Sobre Embercom</Subheading>}
        heading="Una agencia de software en vanguardia."
        showButton={false}
        description={
          <>
            Nos convertirnos en un socio y aliado técnico de nuestros clientes para que nos enfoquemos en desarrollar soluciones tecnológicas de alta calidad, mientras ellos pueden concentrarse en el core de su negocio.
            <br/><br/>
            Este enfoque nos permite crear software pensando en el futuro y establecer relaciones con nuestros clientes que durarán muchos años.
          </>
        }
        imageSrc={AgencyTeamRight}
      />
      <MainFeature1
        subheading={<Subheading>Nuestra Visión</Subheading>}
        heading="Nuestro objetivo es revolucionar el software."
        description={
          <>
            Queremos conocer la misión detrás de tu negocio, entender por qué llega a ese lugar y ayudarte a llegar allí.
            <br/><br/>
            Entregas en tiempo y presupuesto que superan las expectativas. No queremos hacer sólo lo que tú nos pides, queremos ir más allá, entendiendo e implicándonos en tu proceso de desarrollo.
          </>
        }
        buttonRounded={false}
        primaryButtonText="Contáctanos"

        imageSrc={AgencyTeamLeft}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Nuestros Valores</Subheading>}
        heading="Nos regimos por"
        cards={[
          {
            imageSrc: CustomerLoveIconImage,
            title: "Pasión",
            description: "Amamos lo hacemos y creemos en la mejora continua."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Teamwork",
            description: "Unimos nuestras habilidades para lograr resultados sorprendentes, siempre en un ambiente lleno de colaboración, reconocimiento y respeto."
          },
          {
            imageSrc: SupportIconImage,
            title: "Responsabilidad",
            description: "Siempre entregamos los objetivos que se plantearon desde el inicio y aceptamos los desafíos que estos conllevan."
          },
          {
            imageSrc: ReliableIconImage,
            title: "Iniciativa",
            description: "Estamos orgullosos del trabajo que hacemos. Nuestra reputación es nuestra carta de presentación."
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
};
