import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

// import Header from "components/headers/light.js";
import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
// import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import TestimonialsImage1 from 'images/testimonials.jpeg';
import TestimonialsImage2 from 'images/testimonial2.jpeg';

export default () => (
  <AnimationRevealPage>
    <Hero />
    <MainFeature />
    <Features />
    <MainFeature2 />
    {/* <Portfolio /> */}
    <Testimonial
      subheading="Testimonios"
      heading={
        <>
          Clientes que <span tw="text-primary-500">Nos adoran.</span>
        </>
      }
      description="Esto es lo que algunos de nuestros increíbles clientes dicen sobre nuestro equipo."
      testimonials={[
        {
          imageSrc:
            TestimonialsImage2,
          // profileImageSrc:
          //   "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
          quote:
            "Las soluciones de desarrollo web proporcionadas no solo agilizaron nuestras operaciones en línea sino que también mejoraron significativamente la participación de los usuarios en nuestra aplicación.",
          customerName: "Walter Canto",
          customerTitle: "Clean My Ride"
        },
        {
          imageSrc:
            TestimonialsImage1,
          // profileImageSrc:
          //   "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
          quote:
            "Desde la consulta inicial hasta la implementación de nuestras soluciones personalizadas, cada paso estuvo marcado por el profesionalismo, la experiencia y una profunda comprensión de nuestros desafíos únicos.",
          customerName: "Rocío Ventura",
          customerTitle: "URPlace"
        }
      ]}
      textOnLeft={true}
    />
    {/* <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    /> */}
    {/* <Blog /> */}
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
