import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
// import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

// import SupportIconImage from "../../images/support-icon.svg";
import WebIconImage from "../../images/img_services_web_color.svg";
import MobileIconImage from "../../images/img_services_mobile_color.svg";
import UXIconImage from "../../images/img_services_ux_color.svg";
import CloudIconImage from "../../images/img_services_cloud_color.svg";
// import ShieldIconImage from "../../images/shield-icon.svg";
// import CustomizeIconImage from "../../images/customize-icon.svg";
// import FastIconImage from "../../images/fast-icon.svg";
// import ReliableIconImage from "../../images/reliable-icon.svg";
// import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: WebIconImage,
      title: "Desarrollo Web",
      description: "El desarrollo web permite a las empresas lograr una experiencia consistente y adaptable en múltiples plataformas. Al integrar el desarrollo front-end y backend, creamos experiencias distintivas y al mismo tiempo optimizamos la velocidad de entrega."
    },
    { 
      imageSrc: MobileIconImage, 
      title: "Desarrollo Móvil", 
      description: "Nuestro equipo opera rápidamente y puede ayudarte a seleccionar la herramienta ideal para satisfacer las necesidades de tu proyecto. En esencia, nuestra estrategia de desarrollo móvil se centra en brindar una experiencia de usuario excepcional y al mismo tiempo aprovechar la sinergia entre los componentes front-end y backend." 
    },
    { 
      imageSrc: UXIconImage, 
      title: "User Experience",
      description: "En Embercom cubrimos cada rama que un proyecto necesita para destacarse del resto combinando estrategia, tecnología y creatividad con el objetivo de superar siempre las expectativas de nuestros clientes."
    },
    { 
      imageSrc: CloudIconImage, 
      title: "Cloud Services",
      description: "Nuestro equipo ofrece opciones de Servicios en la Nube confiables, seguras y rápidas, con el objetivo de ahorrar a nuestros clientes tiempo y dinero en infraestructura."
    },
    // { imageSrc: FastIconImage, title: "PR Campaign" },
    // { imageSrc: SimpleIconImage, title: "Product Expansion" }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Professional <span tw="text-primary-500">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
